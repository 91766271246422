import React from "react";

import AwsSliderStyles from './hoc/captioned-images/styles.scss';
import Captioned from './hoc/captioned-images'

const ImageSwiperSketches = () => {
  return (
    <Captioned
      cssModule={[AwsSliderStyles]}
      screens={[
        {
          media: 'https://res.cloudinary.com/ryandaaang/image/upload/v1614532327/HumansOS/Creation_of_Design_Principles.jpg',
          caption: 'Brainstorming Design Principles',
        },
        {
          media: 'https://res.cloudinary.com/ryandaaang/image/upload/v1615125123/HumansOS/Discussions_About_The_Mail_App.jpg',
          caption: 'Discussing The Mail App',
        },
        {
          media: 'https://res.cloudinary.com/ryandaaang/image/upload/v1615124742/HumansOS/Initial_Ideation_of_Category_Bars.jpg',
          caption: "Initial Ideation of 'Category Bars'",
        },
        // {
        //   media: 'https://res.cloudinary.com/ryandaaang/image/upload/v1614532324/HumansOS/Calendar_Screens.jpg',
        //   caption: 'Calendar App: Week View & Month View',
        // },
        // {
        //   media: 'https://res.cloudinary.com/ryandaaang/image/upload/v1615124843/HumansOS/Calendar_Day_View.jpg',
        //   caption: 'Calendar App: Overview & Day View',
        // },
        {
          media: 'https://res.cloudinary.com/ryandaaang/image/upload/v1614532323/HumansOS/Working_to_Create_Consistent_Gestures_and_Interactions.jpg',
          caption: 'Working to Create Consistent Gestures & Interactions',
        },
      ]}
    ></Captioned>
  );
};

export default ImageSwiperSketches;