import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Button, Title, Text, Box, Span } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import Contact from "../sections/common/Contact";
import imgS1 from "../assets/image/png/HumaneOS-Full.png";
import { device } from "../utils";

import "../assets/fonts/icon-font/css/style.css";

import ImageSwiper from "../components/ImageSwiper";
import ImageSwiperSketches from "../components/ImageSwiperSketches";
import ImageSwiperStyleguide from "../components/ImageSwiperStyleguide";
import ImageSwiperUXPatterns from "../components/ImageSwiperUXPatterns";

const WorkSingle = () => {
  return (
    <>
      <PageWrapper darkTheme>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col lg="8">
                <Text variant="tag">user design</Text>
                <Title variant="secSm" className="my-4">
                  HumaneOS{" "}
                </Title>
                <Text
                  variant="p"
                  css={`
                    max-width: 750px;
                  `}
                >
                  HumaneOS is a mobile operating system designed to help users with goal-setting and achievement through
                  its qualities of mindfulness, encouragement, and clarity. Come along as I dive deep into our design process
                  that got us towards our final product.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <div className="mt-lg-3">
          <Container>
            <Row>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Roles</Text>
                <Title variant="cardBig" className="mt-3">
                  UX/UI Designer
                </Title>
              </Col>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Date</Text>
                <Title variant="cardBig" className="mt-3">
                  Jan 2020 - Mar 2020
                </Title>
              </Col>
              <Col lg="4">
                <Link
                  to="https://www.figma.com/proto/Iec2Tmn2UhyWC9U95RNEW3/INFO-365?node-id=1500%3A9074&scaling=min-zoom" target="_blank"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={1000}
                >
                  <Button arrowRight>OS Preview</Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col xs="11" className="mb-2" style={{marginTop: -25}}>
                <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1613034586/HumaneOS.png"} alt="app screens" className="img-fluid w-100" />
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="pt-4 pb-0">
          <Container>
            <Title variant="secSm" className="mb-5 pb-lg-4" style={{marginTop: -50}}>
              Project Process
            </Title>
            <Title variant="cardBig" className="mb-4" style={{marginTop: -30}}>
              01. The Challenge
            </Title>
            <Text variant="p" className="mb-4">
              Within our team of 6 designers, we were challenged to:
              <ul className="list" style={{marginLeft: 40, marginBottom: 10, marginTop: 10}}>
                <li>Create an original Mobile Design Language</li>
                <li>Create a Design System based on our Design language with a focus on consistency</li>
                <li>Design about 15 core applications based on our Design language and System</li>
              </ul>
            </Text>
            <Text variant="p" className="mb-4">
              Taking inspiration from Google, we completed a set of
              <a href="https://design.google/library/design-sprints/" target="_blank"> Design Sprints </a>
              spread out across 10 weeks due to scheduling constraints as students. Every two weeks, we completed a
              sprint review where we presented our team's progress to our instructor and other students for feedback.
              The final deliverables will be judged by professional designers within the industry.
            </Text>
            <Title variant="cardBig" className="mb-4" style={{marginTop: 60}}>
              02. Design Principles
            </Title>
            <Text variant="p" className="mb-4">
              For our mobile operating system, we brainstormed on principles that were important to us. We created a word cloud and narrowed down on
              four solid principles:
              <ul className="list" style={{marginLeft: 40, marginBottom: 10, marginTop: 10, fontWeight: "bold"}}>
                <li>Goal-oriented</li>
                <li>Self-reflective</li>
                <li>Mindful</li>
                <li>Encouraging</li>
              </ul>
            </Text>
            <Row>
              <Col xs="7" className="mb-5" class="col-xl-7">
                <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1613569555/Design%20Principles%20Wordcloud.jpg"} alt="design principles" className="img-fluid w-100"/>
              </Col>
            </Row>
            <Title variant="cardBig" className="mb-4" style={{marginTop: 60}}>
              03. Design Language 
            </Title>
            <Text variant="p" className="mb-4">
              Using our design principles, we proceeded to create our design language of
              <span style={{fontWeight: "bold"}}> humane </span>
              which helps to drive consistency across our operating system and its apps.
            </Text>
            <Row>
              <Col xs="7" className="mb-5" class="col-xl-7">
                <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1613388450/Design%20Language.png"} alt="design language" className="img-fluid w-100" />
              </Col>
            </Row>
            <Title variant="cardBig" className="mb-4" style={{marginTop: 60}}>
              04. Ideation & Sketches
            </Title>
            <Col xs="13" className="mb-5">
              <ImageSwiperSketches></ImageSwiperSketches>
            </Col>
            <Title variant="cardBig" className="mb-4" style={{marginTop: 60}}>
              05. User Stories
            </Title>
            <Text variant="p" className="mb-4">
              We created user stories to communicate how our operating system and its applications will deliver qualities of mindfulness,
              encouragement, and clarity.
              {/* We created user personas to help us define the context behind our target users, typical behaviors and their needs. Our first
              persona represents a
              <span style={{ color: '#efb27d', fontWeight: "bold" }}> college student </span> who is looking for a mobile device that
              will help them become more focused and organized during school. The second persona represents a
              <span style={{ color: '#b79c84', fontWeight: "bold" }}> high school teacher </span> who is looking for a mobile device that
              will aid them in preventing distractions, setting goals, and maintaining a consistent training schedule. */}
            </Text>
            <Row>
              <Col xs="12" className="mb-5">
                <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1626842883/HumansOS/User_Stories.png"} alt="user stories" className="img-fluid w-100" />
              </Col>
            </Row>
            <Title variant="cardBig" className="mb-4" style={{marginTop: 20}}>
              06. Design System
            </Title>
            <Title variant="card" className="mb-3" style={{marginTop: 40}}>
              Styleguide
            </Title>
            <Text variant="p" className="mb-4">
              Our Styleguide defines the overall visual guidelines and UI components that will be used across multiple apps. To help users 
              become more focused and organized, we created a category bar component that will be implemented through our apps. The category bar 
              allows our users to apply tags to contacts, messages, tasks, and calendar events. Due to the usage of different colors, we
              included color blind-friendly palettes in combination with customizable patterns and icons for accessibility.
            </Text>
            <Col xs="13" className="mb-5">
              <ImageSwiperStyleguide></ImageSwiperStyleguide>
            </Col>
            {/* <Title variant="cardBig" className="mb-4" style={{marginTop: 60}}>
              07. UX Patterns
            </Title> */}
            <Title variant="card" className="mb-3" style={{marginTop: 60}}>
              UX Patterns
            </Title>
            <Text variant="p" className="mb-4">
              We created our own reusable UX patterns to retain consistent styling across our multiple apps. We grouped our apps into
              five different types based on their similar attributes. Since most of the apps that we will be creating are in the list
              and display categories, we created a set of UX patterns for each of them.
            </Text>
            <Col xs="13" className="mb-5">
              <ImageSwiperUXPatterns></ImageSwiperUXPatterns>
            </Col>
            {/* <Title variant="cardBig" className="mb-4" style={{marginTop: 60}}>
              08. System Controls
            </Title> */}
            <Title variant="card" className="mb-3" style={{marginTop: 60}}>
              System Controls
            </Title>
            <Row style={{marginTop: 40}}>
              <Col xs="3" className="mb-5">
                <Title variant="cardSm" className="mb-3" style={{marginTop: 10}}>
                  Return to Home
                </Title>
                <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1615285126/HumansOS/Return_to_Home.png"} className="img-fluid w-100" />
              </Col>
              <Col xs="3" className="mb-5">
                <Title variant="cardSm" className="mb-3" style={{marginTop: 10}}>
                  Quickly Switch Between Apps
                </Title>
                <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1615285126/HumansOS/Quick_Switching_Between_Apps.png"} className="img-fluid w-100" />
              </Col>
              <Col xs="3" className="mb-5">
                <Title variant="cardSm" className="mb-3" style={{marginTop: 10}}>
                  Viewing Notifications
                </Title>
                <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1615288109/HumansOS/Notification_Alert.png"} className="img-fluid w-100" />
              </Col>
              <Col xs="3" className="mb-5" style={{marginTop: 27}}>
                <Title variant="cardSm" className="mb-3">
                  
                </Title>
                <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1615288109/HumansOS/View_Notifications.png"} className="img-fluid w-100" />
              </Col>
            </Row>
            {/* <Title variant="cardSm" className="mb-3">
              Viewing Notifications
            </Title>
            <Row>
              <Col xs="3" className="mb-5">
                <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1615288109/HumansOS/Notification_Alert.png"} className="img-fluid w-100" />
              </Col>
              <Col xs="3" className="mb-5">
                <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1615288109/HumansOS/View_Notifications.png"} className="img-fluid w-100" />
              </Col>
              <Col xs="3" className="mb-5">
                <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1615288109/HumansOS/Notification_Screen_1.png"} className="img-fluid w-100" />
              </Col>
            </Row> */}
            <Title variant="cardSm" className="mb-3">
              Managing Multiple Apps
            </Title>
            <Row style={{marginBottom: 50}}>
              <Col xs="3" className="mb-5">
                <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1615136945/HumansOS/Springboard_1.png"} className="img-fluid w-100" />
              </Col>
              <Col xs="3" className="mb-5">
                <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1615136946/HumansOS/Springboard_2.png"} className="img-fluid w-100" />
              </Col>
              <Col xs="3" className="mb-5">
                <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1615136947/HumansOS/Springboard_3.png"} className="img-fluid w-100" />
              </Col>
              <Col xs="3" className="mb-5">
                <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1615137245/HumansOS/Springboard_Animation.gif"} className="img-fluid w-100" />
              </Col>
            </Row>
            <Title variant="cardBig" className="mb-4">
              07. Flows
            </Title>
            <Text variant="p" className="mb-4">
              To assist users in finding mindfulness, encouragement, and clarity, we came up with the idea to create different flows
              based on the user's current environment such as home, work, or study. Users are able to customize and display relevant
              apps, home screen pages, and notifications for the current situation or environment. We created wireframes of what
              this feature would look like:
            </Text>
            <Row style={{marginBottom: 50}}>
              <Col xs="3" className="mb-5">
                <Title variant="cardSm" className="mb-3" style={{marginTop: 10}}>
                  Home Flow
                </Title>
                <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1623256831/HumansOS/Change_Home_Flow.png"} className="img-fluid w-100" />
              </Col>
              <Col xs="3" className="mb-5">
                <Title variant="cardSm" className="mb-3" style={{marginTop: 10}}>
                  Flow Selection
                </Title>
                <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1623256830/HumansOS/Flow_Selection.png"} className="img-fluid w-100" />
              </Col>
              <Col xs="3" className="mb-5">
                <Title variant="cardSm" className="mb-3" style={{marginTop: 10}}>
                  Changing To Work Flow
                </Title>
                <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1623256830/HumansOS/Work_Flow_Selection.png"} className="img-fluid w-100" />
              </Col>
              <Col xs="3" className="mb-5">
                <Title variant="cardSm" className="mb-3" style={{marginTop: 10}}>
                  Work Flow
                </Title>
                <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1623256831/HumansOS/Work_Flow.png"} className="img-fluid w-100" />
              </Col>
              
              <Col xs="3" className="mb-5">
                <Title variant="cardSm" className="mb-3" style={{marginTop: 10}}>
                  Work Flow
                </Title>
                <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1623257159/HumansOS/Change_Work_Flow.png"} className="img-fluid w-100" />
              </Col>
              <Col xs="3" className="mb-5">
                <Title variant="cardSm" className="mb-3" style={{marginTop: 10}}>
                  Flow Selection
                </Title>
                <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1626812369/HumansOS/Flow_Selection_-_Home.png"} className="img-fluid w-100" />
              </Col>
              <Col xs="3" className="mb-5">
                <Title variant="cardSm" className="mb-3" style={{marginTop: 10}}>
                  Changing To Home Flow
                </Title>
                <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1623257160/HumansOS/Home_Flow_Selection.png"} className="img-fluid w-100" />
              </Col>
              <Col xs="3" className="mb-5">
                <Title variant="cardSm" className="mb-3" style={{marginTop: 10}}>
                  Home Flow
                </Title>
                <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1623257160/HumansOS/Home_Flow.png"} className="img-fluid w-100" />
              </Col>
            </Row>
            <Text variant="p" className="mb-4" style={{marginTop: -50}}>
              <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
                Update: June 7, 2021
              </span>
            </Text>
            <Text variant="p" className="mb-4">
              Apple had just finished presenting their WWDC (Worldwide Developers Conference) Keynote where they gave an in-depth look
              at updates for all of their operating systems including iOS, iPadOS, watchOS, tvOS, and macOS. As I was watching the
              Keynote, Apple introduced a new feature in
              <a href="https://www.apple.com/ios/ios-15-preview/" target="_blank"> iOS 15 </a>
              called
              <a href="https://www.youtube.com/watch?v=0TD96VTf0Xs&t=1105s" target="_blank"> Focus</a>.
              Users can set their device to help them be in the moment by creating a custom Focus. This is done by creating Home Screen
              pages with apps and widgets that apply to moments of focus.
            </Text>
            <Row>
              <Col xs="6" className="mb-5">
                {/* <figure> */}
                <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1623258682/HumansOS/1_-_Apple-iPhone12Pro-iOS15-Focus-060721.png"} className="img-fluid w-75" />
                {/* <figcaption style={{ fontStyle: "italic"}}>Photo by Apple</figcaption>
                </figure> */}
              </Col>
              <Col xs="6" className="mb-5">
                <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1623258684/HumansOS/2_-_Apple-iPhone12Pro-iOS15-Focus-Modes-060721.png"} className="img-fluid w-75" />
              </Col>
            </Row>
            <Text variant="p" className="mb-4" style={{marginTop: -10}}>
              It was amazing to see the designers at Apple bring to life an idea that we had over a year ago when we created Flows for our 
              operating system, HumaneOS. This is a real testament to our level of imagination and creativity that we bring to our daily work. 
              Moving forward, I am confident that I can bring this level of passion and innovation towards my career as a designer.
            </Text>
            {/* <Text variant="p" className="mb-4" style={{fontStyle: "italic"}}>
                Note: We created Flows without any knowledge or awareness of 
                <a href="https://www.android.com/digital-wellbeing/" target="_blank"> Android's Digital Wellbeing feature </a> 
                or its Focus Mode.
            </Text> */}
            <Title variant="cardBig" className="mb-4" style={{marginTop: 70}}>
              08. Core Applications
            </Title>
            <Col xs="13" className="mb-5">
              <ImageSwiper></ImageSwiper>
            </Col>
            <Title variant="cardBig" className="mb-4">
              09. Outcomes
            </Title>
            <Text variant="p" className="mb-4">
              Between five other design teams, our team tied for second place with a 4.8 out of 5 stars rating from a panel of professional
              designers. It was amazing to see what we could accomplish within a time period of 10 weeks. This design project helped me gain
              a deep understanding of designing for mobile devices, creating design systems, and the importance of iterating on feedback.
            </Text>
            <Text variant="p" className="mb-4">
              Before creating our design system, our designs were heavily critiqued by the instructors and our fellow students for lack of consistency.
              We quickly came to understand the importance of having a design system with established guidelines and patterns, especially working across
              a team of multiple designers.
              In the end, I came to learn that to be a great designer, you must:
              <ul className="list" style={{marginLeft: 40, marginBottom: 10, marginTop: 3}}>
                <li>Have a focus on functionality and usability for the user</li>
                <li>Listen to and use design feedback effectively</li>
                <li>Understand <Span fontWeight="bold">who</Span> you're designing for</li>
              </ul>
            </Text> 
          </Container>
        </Section>
        <Section mt="5%" bg="dark">
          <Container>
            <div className="text-center mt-lg-5">
              <Text variant="tag" className="mb-1" color="lightShade">
                Next Project
              </Text>
              <Link to="/australia-bushfires-infographic">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                  `}
                >
                  Australia Bushfires Infographic
                </Button>
              </Link>
            </div>
          </Container>
        </Section>
        <Box py={4}>
          <Contact />
        </Box>
      </PageWrapper>
    </>
  );
};
export default WorkSingle;
