import React from "react";

import AwsSliderStyles from './hoc/captioned-images/styles.scss';
import Captioned from './hoc/captioned-images'

const ImageSwiperStyleguide = () => {
  return (
    <Captioned
      cssModule={[AwsSliderStyles]}
      screens={[
        {
          media: 'https://res.cloudinary.com/ryandaaang/image/upload/v1615128094/HumansOS/Font_Icons.png',
          caption: 'Fonts, Hierarchy, & Icons',
        },
        {
          media: 'https://res.cloudinary.com/ryandaaang/image/upload/v1615129416/HumansOS/True.png',
          caption: 'Category Bar Tags',
        },
        {
          media: 'https://res.cloudinary.com/ryandaaang/image/upload/v1615129418/HumansOS/Deuteranopia.png',
          caption: 'Accessibility: Deuteranopia Color Blindness',
        },
        {
          media: 'https://res.cloudinary.com/ryandaaang/image/upload/v1615129416/HumansOS/Protanopia.png',
          caption: 'Accessibility: Protanopia Color Blindness',
        },
        {
          media: 'https://res.cloudinary.com/ryandaaang/image/upload/v1615129416/HumansOS/Tritanopia.png',
          caption: 'Accessibility: Tritanopia Color Blindness',
        },
      ]}
    ></Captioned>
  );
};

export default ImageSwiperStyleguide;