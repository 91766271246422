import React from "react";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import CoreStyles from './core/styles.scss';
import AnimationStyles from 'react-awesome-slider/src/styled/scale-out-animation/scale-out-animation.scss';

const ImageSwiperUXPatterns = () => {
  return (
    <AwesomeSlider animation="scaleOutAnimation" cssModule={[CoreStyles, AnimationStyles]}>
      <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1616224394/HumansOS/Grouping_Apps.png"/>
      <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1616137147/HumansOS/List_Apps.png"/>
      <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1616137148/HumansOS/Display_Apps.png"/>
    </AwesomeSlider>
  );
};

export default ImageSwiperUXPatterns;