import React from "react";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import CoreStyles from './core/styles.scss';
import AnimationStyles from 'react-awesome-slider/src/styled/scale-out-animation/scale-out-animation.scss';

const ImageSwiper = () => {
  return (
    <AwesomeSlider animation="scaleOutAnimation" cssModule={[CoreStyles, AnimationStyles]}>
      {/* <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1614150662/HumansOS/Lockscreen%20%2B%20Springboard.png"/>
      <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1614151085/HumansOS/Calendar.png"/>
      <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1614151216/HumansOS/Clock.png"/>
      <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1614151083/HumansOS/Health.png"/>
      <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1614151085/HumansOS/Messages.png"/>
      <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1614151090/HumansOS/Music.png"/>
      <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1614151092/HumansOS/Phone.png"/>
      <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1614151097/HumansOS/Photos.png"/>
      <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1614151094/HumansOS/Tasks.png"/>
      <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1614151096/HumansOS/Wallet.png"/> */}
      <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1626956138/HumansOS/Detailed/Lockscreen_Springboard.png"/>
      <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1626956119/HumansOS/Detailed/Calendar.png"/>
      <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1626956120/HumansOS/Detailed/Clock.png"/>
      <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1626956112/HumansOS/Detailed/Health.png"/>
      <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1626956122/HumansOS/Detailed/Messages.png"/>
      <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1626956133/HumansOS/Detailed/Music.png"/>
      <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1626956121/HumansOS/Detailed/Phone.png"/>
      <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1626956142/HumansOS/Detailed/Photos.png"/>
      <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1626956134/HumansOS/Detailed/Tasks.png"/>
      <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1626956136/HumansOS/Detailed/Wallet.png"/>
    </AwesomeSlider>
  );
};

export default ImageSwiper;

// import React from 'react';
// import Swiper from 'react-id-swiper';

// import 'swiper/swiper.scss'

// const ImageSwiper = () => {
//   const params = {
//     pagination: {
//       el: '.swiper-pagination',
//       type: 'progressbar',
//     },
//     navigation: {
//       nextEl: '.swiper-button-next',
//       prevEl: '.swiper-button-prev',
//     }
//   }
//   return (
//     <Swiper {...params}>
//       <div><img data-src="https://homepages.cae.wisc.edu/~ece533/images/airplane.png"/>Test</div>
//       <div>Slide #2</div>
//       <div>Slide #3</div>
//       <div>Slide #4</div>
//       <div>Slide #5</div>
//     </Swiper>
//   )
// };
// export default ImageSwiper;